<template>
  <div>
    <b-tabs pills nav-class="">
      <b-tab title="RU" active>
        <b-form-textarea
          id="merch-desc-ru"
          v-model="textRu"
          placeholder="Пользовательское соглашение"
          rows="7"
        />
      </b-tab>
      <b-tab title="UZ">
        <b-form-textarea
          id="merch-desc-ru"
          v-model="textUz"
          placeholder="Пользовательское соглашение"
          rows="7"
        />
      </b-tab>
      <b-tab title="En">
        <b-form-textarea
          id="merch-desc-ru"
          v-model="textEn"
          placeholder="Пользовательское соглашение"
          rows="7"
        />
      </b-tab>
    </b-tabs>

    <b-button
      class="mt-2 res-w-100"
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      @click.prevent="saveTerms"
    >
      <span class="text-nowrap">Сохранить</span>
    </b-button>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import {
  BTab,
  BTabs,
  BCardText,
  BCardTitle,
  BCard,
  BRow,
  BCol,
  BButton,
  BFormInput,
  BFormSelect,
  BFormTextarea,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axiosIns from "@/libs/axios.js";

export default {
  components: {
    BCard,
    BTab,
    BTabs,
    BCardText,
    BCardTitle,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BFormSelect,
    BFormTextarea,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      textRu: null,
      textUz: null,
      textEn: null,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      const response = await axiosIns.get("v1/ofert");
      this.textRu = response.data.data[0].textRu;
      this.textUz = response.data.data[0].textUz;
      this.textEn = response.data.data[0].textEn;
    },
    async saveTerms() {
      await axiosIns
        .put("v1/ofert/edit", {
          textRu: this.textRu,
          textUz: this.textUz,
          textEn: this.textEn,
        })
        .then((response) => {
          if (response.status == 200) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: `Успешно`,
                  icon: "CheckCircleIcon",
                  variant: "success",
                  text: `Пользовательское соглашение было успешно изменено!`,
                },
              },
              {
                position: "top-right",
              }
            );
          }
        })
        .catch((error) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: `Ошибка`,
                icon: "XIcon" /* XCircleIcon AlertCircleIcon AlertTriangleIcon */,
                variant: "danger",
                text: error.response.data.errorMessage,
              },
            },
            {
              position: "top-right",
            }
          );
          if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        });
    },
  },
};
</script>

<style lang="scss"></style>
