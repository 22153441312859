<template>
  <div>
    <h1 class="mb-2">Настройки</h1>

    <b-row>
      <b-col cols="12" md="6">
        <b-card class="mb-0">
          <h4 class="mb-2">Пользовательское соглашение</h4>
          <SettingsTerms />
        </b-card>
      </b-col>
      <b-col cols="12" md="3">
        <b-card class="mb-2">
          <h4 class="mb-2">Мин. кол-во кэшбека для снятия</h4>
          <b-form-group class="mb-0" label-for="basicInput">
            <b-form-input
              id="basicInput"
              placeholder="Введите кол-во кэшбека"
            />
            <b-button
              class="mt-2 w-100"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
            >
              <span class="text-nowrap">Сохранить</span>
            </b-button>
          </b-form-group>
        </b-card>
      </b-col>

      <b-col cols="12" md="3">
        <b-card class="mb-2">
          <h4 class="mb-2">Мин. кол-во пластиковых карт</h4>
          <b-form-group class="mb-0" label-for="basicInput">
            <b-form-input
              id="basicInput"
              placeholder="Введите кол-во карт"
            />
            <b-button
              class="mt-2 w-100"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
            >
              <span class="text-nowrap">Сохранить</span>
            </b-button>
          </b-form-group>
        </b-card>
      </b-col>

      
    </b-row>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BFormSelect,
} from "bootstrap-vue";
import SettingsTerms from "./SettingsTerms.vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormSelect,
    SettingsTerms,
  },
  directives: {
    Ripple,
  },
};
</script>

<style lang="scss" scoped></style>
